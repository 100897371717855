<template>
  <div class="admin-deep">
    <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
    <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
    <employees-account-table ref="queryData" @tabButtonClick="tabButtonClick" :newsQueryData="newsQueryData"></employees-account-table>
    <dialog-box ref="queryDialog" :dialogShow="dialogShow" :tabData="tabData" @handleClose='handleClose' @jurisdiction='jurisdiction' title="分配角色" componentName="EmployeesRoleBody" :buttonData="[{ label: '取消', action: 'handleClose', size: 'mini' }, {label:'确认',action:'action',size:'mini',type:'primary'}]" @action="action"></dialog-box>
    <!-- 添加管理员 -->
    <el-dialog title="新增账号" v-model="dialogRole" width="40%">
      <div class="mainform">
        <div class="form">
          <el-form size="mini" :model="formData" :rules="newsRules" label-width="110px" class="content bascform">
            <div class="col col8">
              <el-form-item label="员工姓名:" prop="name">
                <el-input v-model="formData.name" placeholder="请输入员工姓名"></el-input>
              </el-form-item>
            </div>
            <div class="col col8">
              <el-form-item label="用户名:" prop="username">
                  <el-input v-model="formData.username" placeholder="请输入用户名" ></el-input>
              </el-form-item>
            </div>
            <div class="col col8">
              <el-form-item label="手机号:" prop="phone">
                  <el-input  v-model="formData.phone" placeholder="请输入手机号" ></el-input>
              </el-form-item>
            </div>
            <div class="col col8">
              <el-form-item label="邮箱:" prop="mail">
                  <el-input v-model="formData.mail" placeholder="请输入邮箱" ></el-input>
              </el-form-item>
            </div>
            <div class="col col8">
              <el-form-item label="部门:" prop="deptName">
                  <el-select v-model="formData.deptName" placeholder="请选择部门">
                    <el-option label="销售部" value="销售部"></el-option>
                    <el-option label="采购部" value="采购部"></el-option>
                    <el-option label="财务部" value="财务部"></el-option>
                    <el-option label="品质部" value="品质部"></el-option>
                    <el-option label="产品部" value="产品部"></el-option>
                </el-select>
              </el-form-item>
            </div>
            <div class="col col8">
              <el-form-item label="密码:" prop="password">
                  <el-input v-model="formData.password" placeholder="请输入密码" show-password></el-input>
              </el-form-item>
            </div>
          </el-form>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button size="mini" @click="clockAssignRoles">取消</el-button>
          <el-button size="mini" type="primary" @click="assignRoles">确认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { requestForm, request } from '@/assets/js/http.js'
import ListSearch from '@/components/actionList/ListSearch.vue'
import ListMenu from '@/components/actionList/ListMenu.vue'
import EmployeesAccountTable from './EmployeesAccountTable.vue'
import DialogBox from '@/components/plug/DialogBox.vue'
import { AdminAccountSearchList, AdminAccountSearchData } from './js/AdminAccount.js'
// import Component from '@/assets/js/components.js'
export default {
  // components: Component.components,
  components: { ListSearch, EmployeesAccountTable, ListMenu, DialogBox },
  name: 'EmployeesAccount',
  data: function () {
    var checkChinese = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入员工ITcode'))
      } else {
        if (value !== '') {
          var reg = /[\u4E00-\u9FA5]/g
          if (reg.test(value)) {
            callback(new Error('不能输入中文'))
          }
        }
        callback()
      }
    }
    return {
      dialogShow: false,
      dialogRole: false,
      tabData: '',
      roleData: '',
      newCheckedCities: '',
      newsQueryData: '',
      childData: { searchList: AdminAccountSearchList, searchData: AdminAccountSearchData },
      menudata: this.$store.state.ViewMenuData.buttonData,
      formData: {
        name: '',
        username: '',
        deptName: '',
        phone: '',
        mail: '',
        password: ''
      },
      newsRules: {
        username: [
          { required: true, validator: checkChinese, trigger: 'blur' }
        ],
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        deptName: [
          { required: true, message: '请选择部门', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      }
    }
  },
  created () {
    // this.getJsData()
  },
  mounted () {
    this.jurisdiction()
  },
  methods: {
    // 获取角色下拉框中的数据
    getJsData () {
      const obj = {
        pageNum: 1,
        pageSize: 10000
      }
      requestForm('/api/system/role/list', 'POST', obj).then((res) => {
        if (res.code === '200') {
          var newOption = []
          res.data.records.forEach((item, index) => {
            newOption.push({
              label: item.roleDesc,
              value: item.roleCode
            })
          })
          this.childData.searchList[4].option = newOption
        }
      })
    },
    // 点击分配角色弹框及提交选中权限
    action () {
      if (this.newCheckedCities) {
        var uID = this.tabData.id
        var rID = this.newCheckedCities
        var newRid = []
        rID.forEach((item, index) => {
          newRid.push(item.id)
        })
        var obj = []
        newRid.forEach((item, index) => {
          obj.push({ userId: uID, roleId: item })
        })
        request('/api/system/user/saveUserRole', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.$refs.queryData.GetTableData()
            this.dialogShow = false
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请选择角色',
          type: 'error'
        })
      }
    },
    // 接收子组件-分配权限数据
    jurisdiction (data) {
      this.newCheckedCities = data
    },
    // 关闭弹框
    handleClose () {
      this.dialogShow = false
    },
    // 获取当前某一条分配角色的数据
    tabButtonClick (data) {
      this.tabData = data
    },
    // 添加管理员弹框
    buttonClick: function (data) {
      this.dialogRole = true
    },
    // 获取添加管理员数据
    assignRoles () {
      if (this.formData.username && this.formData.name && this.formData.password && this.formData.deptName) {
        const obj = {
          username: this.formData.username,
          name: this.formData.name,
          phone: this.formData.phone,
          mail: this.formData.mail,
          deptName: this.formData.deptName,
          password: this.formData.password,
          userType: 1,
          enterpriseCode: sessionStorage.getItem('enterpriseCode')
        }
        if (obj.phone === '' && obj.mail === '') {
          this.$message({
            showClose: true,
            message: '请填写手机号或邮箱！',
            type: 'error'
          })
          return false
        }
        request('/api/system/user/saveSupplierUser', 'post', obj).then((res) => {
          if (res.code === '200') {
            this.$refs.queryData.GetTableData()
            this.dialogRole = false
            this.$message({
              showClose: true,
              message: '员工账号添加成功',
              type: 'success'
            })
          }
        })
      } else {
        this.$message({
          showClose: true,
          message: '请将信息填写完整',
          type: 'error'
        })
        return false
      }
    },
    // 取消
    clockAssignRoles () {
      this.dialogRole = false
    },
    // 查询
    onSearch (data) {
      this.newsQueryData = this.childData.searchData
      this.$refs.queryData.GetTableData(data)
    },
    // 清空
    clearSearch (data) {
      this.newsQueryData = this.childData.searchData
      this.$refs.queryData.GetTableData()
    }
  },
  watch: {
    '$store.state.ViewMenuData': function (val) {
      this.menudata = val.menuData
    },
    newCheckedCities: function (val) {

    }
  }
}
</script>

<style scoped lang="scss">
 @import '@/assets/css/elForm.scss';
  .admin-deep{
    :deep(.el-overlay){
      justify-content: center;
      align-items: center;
      display: flex;
    }
    :deep(.el-dialog__body){
      padding: 0;
    }
    :deep(.el-dialog__header){
      padding: 15px 20px 15px;
      border-bottom: 1px solid #e4e4e4;
    }
    :deep(.el-dialog__footer){
      padding: 15px 20px;
      border: 1px solid #e4e4e4;
    }
    :deep(.el-checkbox){
      margin-bottom: 20px;
    }
    .bascform{
      margin-top: 20px;
    }
  }
  .role-top{
    background-color: rgba(68, 135, 250, 0.2);
    margin-top: 10px;
    padding: 10px 20px;
  }
  .role-cont{
    padding: 20px 70px;
  }
  .subJob{
    position: absolute;
    right: 1px;
    top: 1px;
    border: none;
    background: none;
  }
  .job-left{
    float: left;
    width: 98px;
    margin-right: 12px;
    text-align: right;
  }
  .job-right{
    float: left;
  }
  .job-name{
    margin-bottom: 15px;
  }
</style>
