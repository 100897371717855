<template>
<div class="tableList account-deep">
  <div class="table">
    <el-table :data="tableData" stripe style="width: 100%">
      <el-table-column prop="username" label="账号" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column prop="name" label="姓名" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column prop="phone" label="手机号" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column prop="mail" label="邮箱" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column prop="deptName" label="部门" :show-overflow-tooltip="true"> </el-table-column>
      <el-table-column prop="sysUserRoles" label="角色" :show-overflow-tooltip="true">
        <template #default="scope">
          <span class="sysUserRoles" :key="key" v-for="(item,key) in scope.row.sysUserRoles">{{ item.roleName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template #default="scope">
          <el-button
            size="mini"
            type="text"
            @click="handleShow(scope.$index, scope.row)">分配角色</el-button>
          <el-button
            size="mini"
            type="text"
            @click="DeleteTips(scope.$index, scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="tablepage" v-if="tableData&&tableData.length>0">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :page-size = pageSize
        layout="total, prev, pager, next, jumper"
        :total = total>
      </el-pagination>
    </div>
  </div>
</div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
export default {
  props: ['newsQueryData'],
  name: 'EmployeesAccountTable',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      pageNum: 1, // 当前第几页
      pageSize: pageSize, // 每页显示多少条
      total: 0, // 共多少条
      roleOneData: {},
      tableData: []
    }
  },
  created () {
    this.GetTableData()
  },
  methods: {
    // 分配角色
    handleShow: function (index, data) {
      this.$parent.dialogShow = true
      this.$emit('tabButtonClick', data)
    },
    // 删除
    handleEdit: function (index, data) {
      requestForm('/api/system/user/deleteUser/' + data.id, 'DELETE').then((res) => {
        if (res.code === '200') {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.GetTableData()
        }
      })
    },

    // 删除提示框
    DeleteTips: function (index, data) {
      const that = this
      this.$confirm('删除后，该账号信息将被清空并不可恢复，确定要删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        that.handleEdit(index, data)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 获取table列表数据及分页
    GetTableData (data) {
      if (data === undefined) {
        const obj = {
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        requestForm('/api/system/user/list', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
      } else {
        const obj = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          username: data.username,
          phone: data.phone,
          deptName: data.department,
          name: data.name,
          roleCode: data.role
        }
        requestForm('/api/system/user/list', 'POST', obj).then((res) => {
          if (res.code === '200') {
            this.tableData = res.data.records
            this.total = res.data.total
          }
        })
      }
    },
    // 每页显示多少条
    handleSizeChange (val) {
      this.pageSize = val
      this.GetTableData()
    },
    // 当前第几页
    handleCurrentChange (val) {
      this.pageNum = val
      this.GetTableData()
    }
  },
  watch: {
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/tableList.scss';
.tablepage{
  position: inherit;
}
.account-deep{
  :deep(.el-overlay.is-message-box){
    width: 460px;
  }
}
.sysUserRoles{
  padding-right: 10px ;
}
</style>
