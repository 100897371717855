<template>
<div class="listMenu">
    <el-button :key="key" v-for="(itme,key) in menuData" @click="newForm(itme)" type="text">{{itme.label}}</el-button>
</div>
</template>

<script>
export default {
  name: 'ListMenu',
  props: ['menuData'],
  methods: {
    newForm: function (itme) {
      this.$emit('buttonClick', itme)
    }
  }
}
</script>

<style scoped lang="scss">
.listMenu{
    padding: 8px 30px;
    :deep(*){
      font-size: 16px;
    }
}
</style>
