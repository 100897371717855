export var AdminAccountSearchList = [
  { labe: '用户名', code: 'username', type: 'input' },
  { labe: '手机号', code: 'phone', type: 'input' },
  {
    labe: '部门',
    code: 'department',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '销售部', value: '销售部' },
      { label: '采购部', value: '采购部' },
      { label: '财务部', value: '财务部' },
      { label: '品质部', value: '品质部' },
      { label: '产品部', value: '产品部' }
    ]
  },
  { labe: '姓名', code: 'name', type: 'input' }
]
export var AdminAccountSearchData = {
  username: '',
  phone: '',
  department: '',
  name: ''
}
